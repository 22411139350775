import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiView, UiViewRow } from '../src';
import { ViewRowExample } from './util';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "uiview"
    }}>{`UiView`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/view/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`This component SHOULD wrap your entire app tree`}</p>
    </blockquote>
    <p>{`This component will set up the global styles and providers for @uireact to work properly.`}</p>
    <ul>
      <li parentName="ul">{`Theme context provider`}</li>
      <li parentName="ul">{`Dialogs context provider`}</li>
      <li parentName="ul">{`Global styles`}</li>
      <li parentName="ul">{`Notifications context provider`}</li>
    </ul>
    <h2 {...{
      "id": "installation-️"
    }}>{`Installation ⚙️`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/view`}</p>
    </blockquote>
    <h2 {...{
      "id": "examples-"
    }}>{`Examples 🤓`}</h2>
    <h3 {...{
      "id": "uiview-1"
    }}>{`UiView`}</h3>
    <p>{`This doc page is wrapped in a `}<inlineCode parentName="p">{`< UiView />`}</inlineCode>{` component`}</p>
    <p>{`This is an example of our wrapper:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`export const ViewExample: React.FC = () => {
  const [selectedTheme, setTheme] = React.useState<ThemeColor>(ThemeColor.light);
  const toogleTheme = () => {
    setTheme(selectedTheme === ThemeColor.light ? ThemeColor.dark : ThemeColor.light);
  };

  return (
    <UiView theme={DefaultTheme} selectedTheme={selectedTheme}>
      <p>All the react tree should render inside here</p>
      <UiButton onClick={toogleTheme}>Toggle theme</UiButton>
    </UiView>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "uiviewrow"
    }}>{`UiViewRow`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`UiViewRow`}</inlineCode>{` component is used to separate parts of the content of the application.`}</p>
    <Playground __position={1} __code={'<ViewRowExample />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiView,
      UiViewRow,
      ViewRowExample,
      DefaultLayout,
      _frontmatter
    }} hideThemeSelector mdxType="Playground">
  <ViewRowExample mdxType="ViewRowExample" />
    </Playground>
    <br />
    <p>{`ViewRowExample definition:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`export const ViewRowExample: React.FC = () => {
  const [selectedTheme, setTheme] = React.useState<ThemeColor>(ThemeColor.light);
  const toogleTheme = () => {
    setTheme(selectedTheme === ThemeColor.light ? ThemeColor.dark : ThemeColor.light);
  };

  return (
    <UiView theme={DefaultTheme} selectedTheme={selectedTheme}>
      <UiViewRow weight="10">
        <p>A row with weight 10</p>
      </UiViewRow>
      <UiViewRow weight="50" centeredContent>
        <p>A row with weight 50</p>
      </UiViewRow>
      <UiViewRow>
        <p>A row with default theme</p>
      </UiViewRow>
      <UiViewRow weight="150">
        <p>A row with weight 150</p>
      </UiViewRow>
      <UiViewRow weight="200">
        <p>A row with weight 200</p>
      </UiViewRow>
      <UiViewRow weight="200" noBackground>
        <p>A row without background color</p>
      </UiViewRow>
      <UiViewRow inverseFont category="negative">
        <p>Row with inverse font and category</p>
      </UiViewRow>
      <UiButton onClick={toogleTheme}>Toggle theme</UiButton>
    </UiView>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "props-️"
    }}>{`Props 🛠️`}</h2>
    <h4 {...{
      "id": "uiview-2"
    }}>{`UiView`}</h4>
    <Props of={UiView} mdxType="Props" />
    <h4 {...{
      "id": "uiviewrow-1"
    }}>{`UiViewRow`}</h4>
    <Props of={UiViewRow} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      